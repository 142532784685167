import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import ShowcaseHead from "../components/ShowcaseHeader"
import styled from '../components/Bytedance.css'


class BytedanceShowcae extends React.Component {
  constructor(props){
    super(props)
    this.state = {
     
    }
  }
  render(){
    return(
      <div>
        <Link to="/Showcase"><div className="BackArrow"></div></Link>      
      <div className="Bytedance">
        <div className="InnerBytedance">        
          <div className="Section Section01">
            <div className="HeadContent">
              <ShowcaseHead 
                
                title = "Video Conferences"
                info="MVP"
                date="Date"
                role="Role"
                participant="Participant"
                time="2018.09 - 2019.05"
                rolename="UI & UX Designer"
                avatar01="http://qfxaodfpw.hn-bkt.clouddn.com/avatar%2001.png"
                avatar02="http://qfxaodfpw.hn-bkt.clouddn.com/avatar%2002.png"
                avatar03="http://qfxaodfpw.hn-bkt.clouddn.com/avatar%2003.png"
                avatar04="http://qfxaodfpw.hn-bkt.clouddn.com/avatar%2004.png"
                avatar05="http://qfxaodfpw.hn-bkt.clouddn.com/avatar%2005.png"
                
              />
            </div>
            <div className="HeadMobileImage"></div>
            <div className="Caption00">
                  <h3>Connect. Engage. Create.</h3>
                  <h1>Smooth, collaborative audio video conferences</h1>
                  <div className="Add"></div>
                  <p><span>Feishu's audio/video conferencing is deeply integrated with Chat, Calendar and Docs to support high-quality audio/video conferences </span>anytime, anywhere. </p>
                  <p>The stable multi-party connection, <span>easy-to-use livestreaming and powerful.</span></p>
              </div>
          </div>

          
          <div className="Section Section02">
            <div className="DevicesContent">
              <div className="Devices">
                <div className="DevicesContorl">
                  <div className="contorl01"></div>
                  <div className="contorl02"></div>
                  <div className="contorl03"></div>
                  <div className="contorl04"></div>
                </div>
              </div>
              <div className="DevicesInfo">
                <p><span>Multiple Equiment</span> Connection</p>
                <div className="DevicesCombine">
                  <div className="Combine01"></div>
                  <div className="Combine02"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="Section Section03">
            <div className="meet">
            
              <div className="meetinfo">
                <p>Design Exploring</p>
                <p>2018 - 2019</p>
              </div>
              <div className="meetdescription">
                <p>One-click screen sharing</p>
                <h3><span>During </span>an audio/video conference<span>, anyone can directly share conference materials from their screen, allowing everyone to effectively participate in the discussion and interact remotely in real time.</span></h3>
              </div>
              <div className="meetcollection"></div>
            </div>
          </div>

          <div className="Section Section04">
            <div className="ViewHead">
              <h1><span>Quick audio・video calls from </span>Chat</h1>
              <div className="Time">
              <div className="DevicesCombine">
                  <div className="Combine01"></div>
                  <div className="Combine02"></div>
              </div>
              <p>19.04.20</p>
              </div>
              <h3><span>Fully integrated with Chat, the audio/video conferencing allows you to make high-quality audio and video calls at any h3oint during a one-on-one or group chat to ensure convenient communication.Just click a </span>meeting invitation<span> or create a </span>chat group <span>from your calendar and you're on your way.</span></h3>
            </div>
            
            <div className="View_Mob_V">
              <div className="View_Mob_V_Info">
                <h3>Mobile Phone</h3>
                <div className="Splitline"></div>
                <p>Stable multi-party connection</p>
              </div>
              <div className="Imageview"></div>
              <div className="CaptionDescription01">
                <div className="ModuelName">
                  <p>Vertical View</p>
                </div>
                <div className="NumberInfo">
                  <div className="Splitline"></div>
                  <p>01</p>
                </div>
              </div>
            </div>

            <div className="View_Mob_H">
              <div className="Calling01"></div>
              <div className="BadgeTop">
                <div className="Badgetop01"></div>
                <div className="Badgetop02"></div>
              </div>
              <div className="CallingInfo">
                <p>Horizontal View</p>
                <p><span>Calling...</span></p>
              </div>

              <div className="BadgeBottom">
                <div className="Badgebottom01"></div>
                <div className="Badgebottom02"></div>
              </div>
              <div className="Calling02"></div>

            </div>

            <div className="View_Desktop_V">
              <div className="View_Desktop_V_Info">
                <h3>Desktop</h3>
                <div className="Splitline"></div>
                <p>Team Cooperation</p>
              </div>
              <div className="Imageview"></div>
              <div className="CaptionDescription02">
                <div className="ModuelName">
                  <p>Immmersion Deisgn</p>
                </div>
                <div className="NumberInfo">
                  <div className="Splitline"></div>
                  <p>01</p>
                </div>
              </div>
            </div>
          </div>

          <div className="Section Section05">
                <div className="Real">
                <div className="IndoorCaption">
                  <div className="IndoorInfo">
                    <div className="Add"></div>
                    <p>Online Office </p>
                  </div>
                  <h2>Take your office with you</h2>
                  <h3><span>You can easily start or join an </span>audio/video conference<span> from your computer or cell and seamlessly switch between multiple devices, providing versatile options for convenient business travel.</span></h3>
                  <div className="Wave">
                    <div className="Splitline"></div>
                    <div className="WaveImage"></div>
                  </div>
                </div>
                </div>
          </div>

          <div className="Section Section06">
            <div className="View_Romm_HeaderImage"></div>
            <div className="View_Room_Caption">
              <div className="View_Room_Top">
                <div className="Badge"></div>
                <h1>View Room ・Contorller</h1>
                <div className="Add"></div>
                <h3><span>Regardless of the network quality, you can always </span>boost<span> your team's productivity and improve the meeting experience with video calls that enable stable connection among as many as </span>100 parties, <span>whether you are working with </span>mobile devices or PCs.</h3>
              </div>
              <div className="View_Room_Info">
                <div className="View_Room_Logo">
                  <div className="Icon_Lark"></div>
                  <div className="LoginWay">
                    <p>Lark Account Login</p>
                    <p><span>Cool Features</span></p>
                  </div>                              
                </div>
                <div className="View_Room_Date">
                    <p>April 04.2019</p>
                  </div> 
              </div>
            </div>
          </div>

          <div className="Section Section07">
          <div className="IpadImage"></div>
            <div className="ContorllerPhone">
              <div className="ContorllerPhoneTop">
                <div className="PhoneImage"></div>
                <div className="Caption">
                  <p>01<span>/03</span></p>
                  <h2>View Controller・<br/>iPhone</h2>
                  <div className="Add"></div>
                  <p>Scan code or log in with a Lark account for the View Room running on the Mac mini via the Lark mobile terminal.Then configure the conference room. </p>
                  <p>The configuration is completed and the matching code stage is entered.</p>
                </div>
              </div>

              <div className="ControllerPhoneBottom">
                  <div className="PhoneDescription">
                    
                      <div className="Circle"></div>
                      <div className="CirclelInfo">
                        <p>View Room Design Proposal</p>
                        <p>Multiple Devices</p>
                      </div>
                          
                  </div>
                  <div className="CubeCollection">
                      <div className="Cube01">
                        <h2>Avaliable</h2>
                        <p>Real Time State</p>
                      </div>
                      
                      <div className="Cube02">
                      
                        <h2>Equipment Control</h2>
                        <p>Real Object</p>
                      </div>
                      
                      <div className="Cube03">
                      
                        <h2>Gaussian Blur</h2>
                        <p>Design Style</p>
                      </div>
                    </div>  
              </div>
            </div>
           
            <div className="ControllerIpad">
              
              <div className="Caption02">
                  <p>02<span>/03</span></p>
                  <h2>View Controller・<br/>iPad</h2>
                  <div className="Add"></div>
                  <p>Click on the iPad to start an immediate meeting and enter the interface of the control items in the meeting. At the same time, Room will display the video screen of the meeting.</p>
                  <p>On the initialization interface of Mac mini-View Room, guide IT administrator to open the View Room Controller of iPad mini to match.</p>
              </div>
            </div>
          </div>

          <div className="Section Section08">
            <div className="Room"></div>
            <div className="RoomCube">
              <div className="RoomHeader">
                <div className="Number">
                  <div className="Add"></div>
                  <p>03<span>/03</span></p>
                </div>
                <div className="FullScreen">
                  <p>FullScreen</p>
                </div>                
              </div>
              <div className="RoomText01">
                <h3>The IT administrator opens the Mac mini, connects the mouse and keyboard, clicks View Room to open the App, and enters the initialization interface.</h3>
              </div>              
              <div className="RoomTitle">
                <div className="SplitLineBold"></div>
                <div className="SplitLineThin"></div>
                <div className="RoomTitleContent">
                  <h1>View Room・Mac Mini</h1>
                  <div className="Circle"></div>
                </div>                
                <div className="SplitLineThin"></div>
                <div className="SplitLineBold"></div>
              </div>
              <div className="RoomBottom">
                <h3>The PC side performs the local screen operation through the Lark client, and the participant's equipment is cast to the Room.</h3>
              </div>             
            </div>
          </div>

        </div>
      </div>
      </div>
    )
  }
}
export default BytedanceShowcae
